import useEditorStore from "../../common/stores/editor";

export const useSaleFunnel = ()=>{
  const editorStore = useEditorStore();
  const editingPageType = computed(() => {
    return editorStore.getEditingPageType ?? '';
  });

  const isPostPurchasePage = computed(() => {
    return editingPageType.value === 'POST_PURCHASE';
  });

  const isSalePage = computed(() => {
    return editingPageType.value === 'GP_FUNNEL_PAGE';
  });

  return {
    isPostPurchasePage,
    isSalePage,
    editingPageType
  }
}
