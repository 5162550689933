import useShopStore from '@/stores/shop';
import { computed } from 'vue';
export default function useLocationHandlers() {
  const shopifyDomain = computed(() => useShopStore().getShopDomain?.split('.')?.[0] ?? '');
  const shopifyAppName = import.meta.env.VITE_GEMPAGES_APP_ID;

  /**
   * Get Shopify admin URL
   * @param pathName [apps]/[shopifyAppName]/[pathName]
   * @returns 'https://admin.shopify.com/store/[shopifyDomain]/[apps]/[shopifyAppName]/[pathName]'
   */
  const getShopifyAdminUrl = (pathName: string) => `https://admin.shopify.com/store/${shopifyDomain.value}/${pathName}`;

  /**
   * Handle replacing the current window location
   * @param pathName [app]/[pathName] Example: 'app/pricing'
   */
  const handleReplaceLocation = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(`apps/${shopifyAppName}/${pathName}`);
    window.top?.location.replace(adminUrl);
  };

  /**
   * Handle opening the GP page in a new tab
   * @param pathName [app]/[pathName] Example: 'app/pricing'
   */
  const handleOpenPageGPInNewTab = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(`apps/${shopifyAppName}/${pathName}`);
    window.open(adminUrl, '_blank');
  };

  /**
   * Handle opening Shopify page in a new tab
   * @param pathName [pathName] Example: 'themes' or 'products'
   */
  const handleOpenPageShopifyInNewTab = (pathName: string) => {
    const adminUrl = getShopifyAdminUrl(pathName);
    window.open(adminUrl, '_blank');
  };

  return {
    getShopifyAdminUrl,
    handleReplaceLocation,
    handleOpenPageGPInNewTab,
    handleOpenPageShopifyInNewTab,
  };
}
