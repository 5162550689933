import type { AnimationConfig } from './types';

import {
  AnimationType,
  AnimationSetting,
  AnimationEasingType,
  AnimationTriggerType,
  AnimationDirectionType,
  AnimationZoomDirectionType,
} from './types';

import AnimationFade from './assets/animation-fade.svg';
import AnimationZoom from './assets/animation-zoom.svg';
import AnimationNone from './assets/animation-none.svg';
import AnimationSlide from './assets/animation-slide.svg';
import AnimationShake from './assets/animation-shake.svg';

export const ANIMATION_PROPS = {
  [AnimationType.None]: {
    label: 'None',
    image: AnimationNone,
  },
  [AnimationType.Fade]: {
    label: 'Fade',
    image: AnimationFade,
  },
  [AnimationType.Slide]: {
    label: 'Slide',
    image: AnimationSlide,
  },
  [AnimationType.Zoom]: {
    label: 'Zoom',
    image: AnimationZoom,
  },
  [AnimationType.Shake]: {
    label: 'Shake',
    image: AnimationShake,
  },
};

export const ANIMATION_TRIGGER_TAB_PROPS: Array<{ id: AnimationTriggerType; label: string }> = [
  {
    id: AnimationTriggerType.Appear,
    label: 'When appear',
  },
  {
    id: AnimationTriggerType.Hover,
    label: 'When hover',
  },
];

export const SUPPORT_LOOP_ANIMATION: Array<AnimationType> = [AnimationType.Shake];

export const SUPPORT_DIRECTION_ANIMATION: Array<AnimationType> = [AnimationType.Slide];

export const SUPPORT_ANIMATION_BY_TRIGGER: Record<AnimationTriggerType, Array<AnimationType>> = {
  [AnimationTriggerType.Appear]: [
    AnimationType.None,
    AnimationType.Fade,
    AnimationType.Slide,
    AnimationType.Zoom,
    AnimationType.Shake,
  ],
  [AnimationTriggerType.Hover]: [AnimationType.None, AnimationType.Zoom, AnimationType.Shake],
};

export const ANIMATION_DIRECTION_OPTIONS: Array<{ value: AnimationDirectionType; icon: string }> = [
  {
    value: AnimationDirectionType.Right,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M7.03685 5.2678C7.28724 5.0302 7.6932 5.0302 7.9436 5.2678C8.19399 5.50539 8.19399 5.89061 7.9436 6.12821L4.52191 9.37503L16.74 9.37503C17.0851 9.37503 17.365 9.65485 17.365 10C17.365 10.3452 17.0851 10.625 16.74 10.625L4.52186 10.625L7.9436 13.8719C8.19399 14.1095 8.19399 14.4947 7.9436 14.7323C7.6932 14.9699 7.28724 14.9699 7.03685 14.7323L2.5031 10.4303C2.25271 10.1927 2.25271 9.80744 2.5031 9.56985L7.03685 5.2678Z" fill="currentColor"/>
      </svg>
    `,
  },
  {
    value: AnimationDirectionType.Left,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M12.6435 5.2678C12.3931 5.0302 11.9871 5.0302 11.7367 5.2678C11.4863 5.50539 11.4863 5.89061 11.7367 6.12821L15.1584 9.37503L2.94033 9.37503C2.59515 9.37503 2.31533 9.65485 2.31533 10C2.31533 10.3452 2.59515 10.625 2.94033 10.625L15.1584 10.625L11.7367 13.8719C11.4863 14.1095 11.4863 14.4947 11.7367 14.7323C11.9871 14.9699 12.3931 14.9699 12.6435 14.7323L17.1772 10.4303C17.4276 10.1927 17.4276 9.80744 17.1772 9.56985L12.6435 5.2678Z" fill="currentColor"/>
      </svg>
    `,
  },
  {
    value: AnimationDirectionType.Down,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.625 15.4697L10.625 2.98462C10.625 2.63944 10.3452 2.35962 10 2.35962C9.65482 2.35962 9.375 2.63944 9.375 2.98462L9.375 15.4697L6.06694 12.1616C5.82286 11.9175 5.42714 11.9175 5.18306 12.1616C4.93898 12.4057 4.93898 12.8014 5.18306 13.0455L9.38128 17.2437C9.72299 17.5854 10.277 17.5854 10.6187 17.2437L14.8169 13.0455C15.061 12.8014 15.061 12.4057 14.8169 12.1616C14.5729 11.9175 14.1771 11.9175 13.9331 12.1616L10.625 15.4697Z" fill="currentColor"/>
      </svg>
    `,
  },
  {
    value: AnimationDirectionType.Up,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M9.375 4.38995L9.375 16.875C9.375 17.2202 9.65482 17.5 10 17.5C10.3452 17.5 10.625 17.2202 10.625 16.875L10.625 4.38995L13.9331 7.69801C14.1771 7.94209 14.5729 7.94209 14.8169 7.69801C15.061 7.45393 15.061 7.0582 14.8169 6.81412L10.6187 2.6159C10.277 2.27419 9.72299 2.27419 9.38128 2.6159L5.18306 6.81412C4.93898 7.0582 4.93898 7.45393 5.18306 7.69801C5.42714 7.94209 5.82286 7.94209 6.06694 7.69801L9.375 4.38995Z" fill="currentColor"/>
      </svg>
    `,
  },
];

export const HORIZONTAL_DIRECTIONS = [AnimationDirectionType.Left, AnimationDirectionType.Right];
export const VERTICAL_DIRECTIONS = [AnimationDirectionType.Up, AnimationDirectionType.Down];

export const ANIMATION_ZOOM_DIRECTION_OPTIONS: Array<{ value: AnimationZoomDirectionType; icon: string }> = [
  {
    value: AnimationZoomDirectionType.In,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 8.77264C3.25 5.72257 5.72257 3.25 8.77264 3.25C11.8227 3.25 14.2953 5.72257 14.2953 8.77264C14.2953 10.2728 13.6971 11.6333 12.7263 12.6286C12.7085 12.6429 12.6912 12.6583 12.6747 12.6748C12.6582 12.6913 12.6428 12.7085 12.6285 12.7264C11.6332 13.6972 10.2728 14.2953 8.77264 14.2953C5.72257 14.2953 3.25 11.8227 3.25 8.77264ZM13.0993 13.9833C11.9259 14.9587 10.4178 15.5453 8.77264 15.5453C5.03221 15.5453 2 12.5131 2 8.77264C2 5.03222 5.03221 2 8.77264 2C12.5131 2 15.5453 5.03222 15.5453 8.77264C15.5453 10.4178 14.9587 11.926 13.9832 13.0994L17.812 16.9283C18.0561 17.1723 18.0561 17.5681 17.812 17.8121C17.568 18.0562 17.1722 18.0562 16.9282 17.8121L13.0993 13.9833ZM9.48821 6.02768C9.48821 5.6825 9.20839 5.40268 8.86321 5.40268C8.51804 5.40268 8.23821 5.6825 8.23821 6.02768V8.23831H6.02759C5.68241 8.23831 5.40259 8.51813 5.40259 8.86331C5.40259 9.20849 5.68241 9.48831 6.02759 9.48831H8.23821V11.6989C8.23821 12.0441 8.51804 12.3239 8.86321 12.3239C9.20839 12.3239 9.48821 12.0441 9.48821 11.6989V9.48831H11.6988C12.044 9.48831 12.3238 9.20849 12.3238 8.86331C12.3238 8.51813 12.044 8.23831 11.6988 8.23831H9.48821V6.02768Z" fill="currentColor"/>
      </svg>
    `,
  },
  {
    value: AnimationZoomDirectionType.Out,
    icon: `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 8.77361C3.25 5.72309 5.72331 3.25 8.77449 3.25C11.8257 3.25 14.299 5.72309 14.299 8.77361C14.299 10.273 13.7015 11.6328 12.7315 12.6281C12.713 12.6429 12.6951 12.6588 12.6779 12.676C12.6608 12.6931 12.6448 12.711 12.6301 12.7295C11.6347 13.6996 10.2744 14.2972 8.77449 14.2972C5.72331 14.2972 3.25 11.8241 3.25 8.77361ZM13.1028 13.9846C11.929 14.9604 10.4203 15.5472 8.77449 15.5472C5.03312 15.5472 2 12.5147 2 8.77361C2 5.03256 5.03312 2 8.77449 2C12.5158 2 15.549 5.03256 15.549 8.77361C15.549 10.4189 14.9623 11.9272 13.9867 13.1008L17.8165 16.93C18.0606 17.1741 18.0606 17.5698 17.8166 17.8139C17.5725 18.058 17.1768 18.058 16.9327 17.814L13.1028 13.9846ZM6.02868 8.23877C5.6835 8.23877 5.40368 8.51859 5.40368 8.86377C5.40368 9.20895 5.6835 9.48877 6.02868 9.48877H11.7016C12.0468 9.48877 12.3266 9.20895 12.3266 8.86377C12.3266 8.51859 12.0468 8.23877 11.7016 8.23877H6.02868Z" fill="currentColor"/>
      </svg>
    `,
  },
];

export const ANIMATION_EASING_OPTIONS = [
  { label: 'Ease in out', value: AnimationEasingType.Ease },
  { label: 'Ease in', value: AnimationEasingType.EaseIn },
  { label: 'Ease out', value: AnimationEasingType.EaseOut },
  { label: 'Linear', value: AnimationEasingType.Linear },
];

export const ANIMATION_FADE_DEFAULT_SETTING = {
  [AnimationSetting.Delay]: '0',
  [AnimationSetting.Speed]: 1.7,
  [AnimationSetting.Easing]: AnimationEasingType.EaseOut,
};

export const ANIMATION_SLIDE_DEFAULT_SETTING = {
  [AnimationSetting.Delay]: '0',
  [AnimationSetting.Speed]: 1.7,
  [AnimationSetting.Distance]: undefined,
  [AnimationSetting.Easing]: AnimationEasingType.EaseOut,
  [AnimationSetting.Direction]: AnimationDirectionType.Left,
  [AnimationSetting.IsCustomDistance]: false,
};

export const ANIMATION_APPEAR_ZOOM_DEFAULT_SETTING = {
  [AnimationSetting.Delay]: '0',
  [AnimationSetting.Speed]: 1.7,
  [AnimationSetting.IsFade]: true,
  [AnimationSetting.Easing]: AnimationEasingType.EaseOut,
  [AnimationSetting.ZoomDirection]: AnimationZoomDirectionType.In,
  [AnimationSetting.Scale]: {
    [AnimationZoomDirectionType.In]: [80, 100] as [number | string, number | string],
    [AnimationZoomDirectionType.Out]: [120, 100] as [number | string, number | string],
  },
};

export const ANIMATION_HOVER_ZOOM_DEFAULT_SETTING = {
  [AnimationSetting.Delay]: '0',
  [AnimationSetting.Speed]: 1.7,
  [AnimationSetting.IsFade]: false,
  [AnimationSetting.Easing]: AnimationEasingType.EaseOut,
  [AnimationSetting.ZoomDirection]: AnimationZoomDirectionType.In,
  [AnimationSetting.Scale]: {
    [AnimationZoomDirectionType.In]: [100, 120] as [number | string, number | string],
    [AnimationZoomDirectionType.Out]: [100, 80] as [number | string, number | string],
  },
};

export const ANIMATION_SHAKE_DEFAULT_SETTING = {
  [AnimationSetting.Delay]: '0',
  [AnimationSetting.Speed]: 0.7,
  [AnimationSetting.Loop]: false,
  [AnimationSetting.Intensity]: 11,
  [AnimationSetting.Easing]: AnimationEasingType.Linear,
};

export const ANIMATION_DEFAULT_SETTING = {
  [AnimationTriggerType.Appear]: {
    [AnimationType.Fade]: ANIMATION_FADE_DEFAULT_SETTING,
    [AnimationType.Zoom]: ANIMATION_APPEAR_ZOOM_DEFAULT_SETTING,
    [AnimationType.Slide]: ANIMATION_SLIDE_DEFAULT_SETTING,
    [AnimationType.Shake]: ANIMATION_SHAKE_DEFAULT_SETTING,
  },
  [AnimationTriggerType.Hover]: {
    [AnimationType.Fade]: ANIMATION_FADE_DEFAULT_SETTING,
    [AnimationType.Zoom]: ANIMATION_HOVER_ZOOM_DEFAULT_SETTING,
    [AnimationType.Slide]: ANIMATION_SLIDE_DEFAULT_SETTING,
    [AnimationType.Shake]: ANIMATION_SHAKE_DEFAULT_SETTING,
  },
};

export const ANIMATION_DEFAULT_CONFIG: AnimationConfig = {
  enabled: false,
  trigger: AnimationTriggerType.Appear,
  triggerConfig: {
    [AnimationTriggerType.Appear]: {
      animation: AnimationType.Fade,
      setting: ANIMATION_DEFAULT_SETTING[AnimationTriggerType.Appear],
    },
    [AnimationTriggerType.Hover]: {
      animation: AnimationType.None,
      setting: ANIMATION_DEFAULT_SETTING[AnimationTriggerType.Hover],
    },
  },
};

export const SCALE_RANGE_MAP = {
  [AnimationTriggerType.Appear]: {
    [AnimationZoomDirectionType.In]: [0, 100],
    [AnimationZoomDirectionType.Out]: [100, 200],
  },
  [AnimationTriggerType.Hover]: {
    [AnimationZoomDirectionType.In]: [100, 200],
    [AnimationZoomDirectionType.Out]: [0, 100],
  },
};

export const HIDE_CONTROL_ON_ELEMENTS = ['Sticky'];

export const RANGE_SLIDE_SETTINGS = [
  AnimationSetting.Intensity,
  AnimationSetting.Distance,
  AnimationSetting.Speed,
  AnimationSetting.Scale,
];
